import * as React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import { rhythm, scale } from "@Utils/typography";
import PageHead from "@Components/PageHead";

import "@styles/blog.scss";

export interface BlogProps {
  data: any;
  location: string;
}

function Blog({ data, location }: BlogProps) {
  const posts = data.allMdx.edges;
  return (
    <>
      <PageHead backgroundImg={data.blogHero.childImageSharp.fluid}>
        <h2 className="head-title">
          <span>Blog</span>
        </h2>
      </PageHead>
      <div className="blog-page">
        <div className="container">
          {posts.map(({ node }: any) => {
            const title = node.frontmatter.title || node.fields.slug;
            const blogLink = node.fields.slug;
            const blogImage = node.frontmatter.banner.childImageSharp.fluid;
            return (
              <div
                className="blog-list"
                style={{
                  ...scale(0.8),
                  maxWidth: rhythm(60),
                }}
                key={node.fields.slug}
              >
                <div className="blogList-imageContainer">
                  <Img fluid={blogImage} alt="Post Image" />
                </div>
                <div className="blogList-container">
                  <div>
                    <Link to={blogLink}>
                      <h1
                        style={{
                          margin: `${rhythm(2 / 4)} 0`,
                        }}
                      >
                        {title}
                      </h1>
                    </Link>
                    <p className="blog-date">
                      {node.frontmatter.date} - {node.fields.author}
                    </p>
                  </div>
                  <p className="description">{node.frontmatter.description}</p>
                  <Link className="read-more" to={blogLink}>
                    Read More &#10140;
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blogHero: file(relativePath: { eq: "blogHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___title], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            author
          }
          frontmatter {
            date
            title
            readLength
            description
            banner {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
